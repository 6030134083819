import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  factoryAddress: string
  multicallAddress: string
  quoterAddress: string
  orderBookAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouterAddress?: string
  v1MixedRouteQuoterAddress?: string
  lockAddress?: string
  tradeRouter?: string
  voterAddress?: string
  rougexTokenAddress?: string
  faucetAddress?: string
  minterAddress?: string
  tradeReaderAddress?: string
  rewardsDistributorAddress?: string
  roxUtilsAddress?: string
  PosnReader?: string
}

const DEFAULT_NETWORKS = [ChainId.ARBITRUM_ONE]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

const ARBITRUM_ONE_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x2140c5B4ef9Cc6d18196C43eeFA9dfF3595467A2',
  tradeRouter: '0xBCcDa22DB89AA35Eff735c433AE0f2642dd3Da07',
  nonfungiblePositionManagerAddress: '0x5Cf3679187B6096a990393bBE67Ec191e3fD7416',
  swapRouterAddress: '0x064d6286b4A9702a85e934AECac2407eb509F34a',
  quoterAddress: '0xA13f5A14c384F550c7168453c1EE0B30F10dc4d0',
  tickLensAddress: '0xE58E92B0A47F29c38A919527099B4d5f7eff6d43',

  roxUtilsAddress: '0x586536752bD0d3D767440E73FdBa2D4016990E2F',
  tradeReaderAddress: '0xE48f2C155e4Cc7D7d2aFBEEc11ce85088e7d5D90',
  orderBookAddress: '0x9D0DF48c859942cFd437F1A5e8e5f8980118e445',

  lockAddress: '0xCD3B42917b4d1f896F0735C1D6EDd44304548Fa4',

  rougexTokenAddress: '0x1F4D8C5f5cb85E923Bff7D73bf08795E68d4b648',
  rewardsDistributorAddress: '0x0d0E982AE77DE7bcd59f9Bd9bf19BBa6d2068964',

  voterAddress: '0x03534749642F4545233CCFDA3c1074fDc8a5511e',
  minterAddress: '0xF73e7422fBBdB4CB4252D0eF3724D9eb6967Aca3',

  multicallAddress: '0x027EDCF231957833e95Bf6131E61EA8345d580d3',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

// eslint-disable-next-line multiline-comment-style
// arbitrum goerli v3 addresses
const GOERLI_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x0149Ae85D16f396A8cc34445876a304097910639',
  tradeRouter: '0xe32A514D929Daa0A9b3853C71bD0e22d80679CBA',
  nonfungiblePositionManagerAddress: '0xDC3e6cA428F36EEfBb6eE2c40AD2E84FDEe2Db19',
  swapRouterAddress: '0x63238B8e910288f6aB3C8Eebef207d0C7b12b66e',
  quoterAddress: '0x4082541102D020f5aC0a9677E2d7dcAa0BcCB631',
  tickLensAddress: '0x6f91826dAb2aFFD602A97EFeAc8Ba99FA9226062',
  roxUtilsAddress: '0xb92C858f8208C82bE209d347b378BF7d63eBb15F',
  tradeReaderAddress: '0xD4f02851F730e8Ad23560C8d02236E7904885D43',
  orderBookAddress: '0xd95E11E8288F94D5E7eF14DB3f7a7F4D85b5b6fD',

  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',

  lockAddress: '0x47Cd8AAae6AaDa375AB712bD2340aBD05d403566',
  rougexTokenAddress: '0x3d3fADCbeba5e68a35581681b6a2D650DAd292f4',
  rewardsDistributorAddress: '0x7F80E24A4C482584605c592908fe3278732F5838',

  voterAddress: '0x86395AB62F130E77D1CF237C6d6EBa8F6A7DDc3d',
  minterAddress: '0x55DF366DAb41B8207c7060b9ae67D257bB9A655D',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

const Sepolia_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xa55F2744DA15cD95822E8e0958305C58e32F9a98',
  tradeRouter: '0xE84594F5B8740E428405f6928C493ef63A318dbA',
  nonfungiblePositionManagerAddress: '0x0Fe7ACf5a758144536e415540e560650B79F6214',
  swapRouterAddress: '0xf8D61E10845893c12B9C495e01976C43F957b529',
  quoterAddress: '0x86eFaA010f65B17dD7504fA7450d56aEcC77bcAD',
  tickLensAddress: '0x4Eb761FCdEC79825b50d7572AdC02601b453639b',
  roxUtilsAddress: '0x75B801637DBB14E59B6Bf378b558601B7B219057',
  tradeReaderAddress: '0xF18F2234823130C50EeD4736893a328Ab4ab54D0',
  orderBookAddress: '0x06422f49ab551c6D7e1ad30af4B7A80f5712d13d',

  multicallAddress: '0x532a5B1A2F8ccE4f05F8b2a9A262c7097B4356ec',

  lockAddress: '0x48878653673113bD4DbEd485a34E194735BA7E10',
  rougexTokenAddress: '0x00fA700a16eF7B3c968Bb1dCD4C6bA697bA73fb9',
  rewardsDistributorAddress: '0x20B7ab1AD1517Bd17cD2F4dc5C29920Bff92ba82',

  voterAddress: '0x780A9Fa3B434b99A2f7751B00c6fC269f19871Bd',
  minterAddress: '0x918891120A62B0F2a91012a8De9f10Dc30a9E3Cd',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}
const BLAST_TEST_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xa50882176639588779FE95a506d551B25e084197',
  tradeRouter: '0x80e795611F22AB767C2E1b9028C36ce31ff650d3',
  nonfungiblePositionManagerAddress: '0x2edA5C0E2C5619cc4ecab70eaEF76EB3A1fBDD2A',
  swapRouterAddress: '0x716401742b57cB30A899A806A77D12320B3aDC69',
  quoterAddress: '0xad7a49a21838f27cD891eFe7479a2B63F43C70Cf',
  tickLensAddress: '0x7D68d90D580B28C48Db2300156BFd13665d12056',
  roxUtilsAddress: '0x9370E5Bc3754c3c4a6C78c1700618FFdB8d3324a',
  tradeReaderAddress: '0x229dfFD05d8610649aecFE3E8408088607a848D7',
  orderBookAddress: '0x8B54F3d99Ca7E58a2CBde3537497411EFa7137C9',

  multicallAddress: '0x1eF53C45b070191cBD7C5Fc88e2Ddafaa29DE1a9',

  lockAddress: '0x964fA323cAc7324F6D100c4c9116581d242369f7',
  rougexTokenAddress: '0x2E9aCfE4b7FBEa12f646e5Cd4C4D6bd283f9f54d',
  rewardsDistributorAddress: '0xfaFd674b71db9bb3eb9EC711844dE228237766bD',

  voterAddress: '0x289065a6eE1192C738B5D5589cb5e47371446f8E',
  minterAddress: '0xB9ABeD8aE0A09c2d49aCD60281E2941D68B2b7Ea',
  PosnReader: '0x716ee1b5AFB8c2B3eDC1C0392B93e91d824CeD6e',
  faucetAddress: '0x2516F4Cb4De50CC62B8705d4067C42b451b5A36c',
}
//
// "USDB" : "0x4300000000000000000000000000000000000003",
//  "WETH" : "0x4300000000000000000000000000000000000004",
//
//  "rougeX" : "0xf26Bd9BA435395F26634C9Be5b717C6d10675897",
//  "AlgebraInterfaceMulticall" : "0xdf119e932877E61e5C2DB627C70130D37E45814C",
//  "RoguexFactory" : "0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0",
//  "RoxUtils" : "0x9C6C26F5D308B22254a57a87744E0c018BE20dC7",
//  "PerpUtils" : "0xDa8B685ceB09D7cb990CE155a3eA91b7D047e033",
//  "RoxSpotPoolDeployer" : "0xF22FF8f7f430a2d07efB88015853D52C88BC286d",
//  "RoxPerpPoolDeployer" : "0x8F833d4314377542107E79bf7005374A42c4499D",
//  "RoxPosnPoolDeployer" : "0x386d7bE5793B061F01601F606f28E1017Cbc9E68",
//  "NonfungiblePositionManager" : "0x37e33B250985FAf4DBdc607FA7454CA76B883A2a",
//  "Quoter" : "0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b",
//  "TickLens" : "0x859997D5686486b023b4fC375f6356FEDf40A923",
//  "PerpRouter" : "0xdD84404e9741fB1Ba2f52906B25126fAE22b4243",
//  "SwapRouter" : "0x8e7a5187117F3dfbae028ac26db00A75aE41F095",
//  "PerpOrderbook" : "0x648AA4e686F538e77EF0DEba5362009483EDC5cb",
//  "VeArtProxy" : "0xd4c4Ae8172253332F4D5E13aAFC096cF35f33905",
//  "VotingEscrow" : "0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21",
//  "RewardsDistributor" : "0x84f7Df1129B8cD21ab4920BE6EaD959E33Ed6b86",
//  "MasterChefFactory" : "0xC37B383B80cd1E29136FCbC54dE77373ccA96479",
//  "SwapMinningFactory" : "0xC1D22E673fbCA772BE2c5EbB86e7Eac72C91d885",
//  "VotingRewardFactory" : "0x03245900162B798726514d9c3c18c1C8EfF2952f",
//  "Voter" : "0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967",
//  "Minter" : "0x7F4701CF364F0481d37272590CbB5F809C8c3B0F",
//  "DisFeeReward" : "0x662267CaA600d9f284356075C3504E78408A819B",
//  "HypervisorFactory" : "0xcd16b287536FF7A761B3574066a91e95635481ec",
//  "Reader" : "0xee75742daFE25220337009949c0C529f56904151",
//  "PosnReader" : "0xFE4F65b03D02944995B31Dc645A2a33021111DF8",
//
const BLAST_MAIN_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0',
  tradeRouter: '0xdD84404e9741fB1Ba2f52906B25126fAE22b4243',
  nonfungiblePositionManagerAddress: '0x37e33B250985FAf4DBdc607FA7454CA76B883A2a',
  swapRouterAddress: '0x8e7a5187117F3dfbae028ac26db00A75aE41F095',
  quoterAddress: '0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b',
  tickLensAddress: '0x859997D5686486b023b4fC375f6356FEDf40A923',
  roxUtilsAddress: '0x9C6C26F5D308B22254a57a87744E0c018BE20dC7',
  tradeReaderAddress: '0xee75742daFE25220337009949c0C529f56904151',
  PosnReader: '0xFE4F65b03D02944995B31Dc645A2a33021111DF8',
  orderBookAddress: '0x648AA4e686F538e77EF0DEba5362009483EDC5cb',

  multicallAddress: '0xdf119e932877E61e5C2DB627C70130D37E45814C',

  lockAddress: '0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21',
  rougexTokenAddress: '0xf26Bd9BA435395F26634C9Be5b717C6d10675897',
  rewardsDistributorAddress: '0x84f7Df1129B8cD21ab4920BE6EaD959E33Ed6b86',

  voterAddress: '0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967',
  minterAddress: '0x7F4701CF364F0481d37272590CbB5F809C8c3B0F',

  faucetAddress: '0x2516F4Cb4De50CC62B8705d4067C42b451b5A36c',
}

const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ARBITRUM_ONE]: ARBITRUM_ONE_ADDRESSES,
  [ChainId.GOERLI]: GOERLI_ADDRESSES,
  [ChainId.ARBITRUM_SEPOLIA]: Sepolia_ADDRESSES,
  [ChainId.BLAST_TEST]: BLAST_TEST_ADDRESSES,
  [ChainId.BLAST_MAIN]: BLAST_MAIN_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].factoryAddress
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
    return memo
  }, {}),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const swapRouter02Address = CHAIN_TO_ADDRESSES_MAP[chainId].swapRouterAddress
    if (swapRouter02Address) {
      memo[chainId] = swapRouter02Address
    }
    return memo
  }, {}),
}
export const TRADE_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeRouter = CHAIN_TO_ADDRESSES_MAP[chainId].tradeRouter
    if (tradeRouter) {
      memo[chainId] = tradeRouter
    }
    return memo
  }, {}),
}

export const LOCK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const lockAddress = CHAIN_TO_ADDRESSES_MAP[chainId].lockAddress
    if (lockAddress) {
      memo[chainId] = lockAddress
    }
    return memo
  }, {}),
}

export const VOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const voterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].voterAddress
    if (voterAddress) {
      memo[chainId] = voterAddress
    }
    return memo
  }, {}),
}

export const MINTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const minterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].minterAddress
    if (minterAddress) {
      memo[chainId] = minterAddress
    }
    return memo
  }, {}),
}

export const ROUGEX_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rougexTokenAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rougexTokenAddress
    if (rougexTokenAddress) {
      memo[chainId] = rougexTokenAddress
    }
    return memo
  }, {}),
}

export const FAUCET_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const faucetAddress = CHAIN_TO_ADDRESSES_MAP[chainId].faucetAddress
    if (faucetAddress) {
      memo[chainId] = faucetAddress
    }
    return memo
  }, {}),
}
export const TRADEREADER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeReaderAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tradeReaderAddress
    if (tradeReaderAddress) {
      memo[chainId] = tradeReaderAddress
    }
    return memo
  }, {}),
}

export const REWARDS_DISTRIBUTOR_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rewardsDistributorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rewardsDistributorAddress
    if (rewardsDistributorAddress) {
      memo[chainId] = rewardsDistributorAddress
    }
    return memo
  }, {}),
}

export const ROX_UTILS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const roxUtilsAddress = CHAIN_TO_ADDRESSES_MAP[chainId].roxUtilsAddress
    if (roxUtilsAddress) {
      memo[chainId] = roxUtilsAddress
    }
    return memo
  }, {}),
}

export const ORDER_BOOK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].orderBookAddress
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}

export const POSN_READER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].PosnReader
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}
