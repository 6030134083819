import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { InterfaceEventName } from '@uniswap/analytics-events'
import { sendAnalyticsEvent } from 'analytics'
import { ReactComponent as BlastIcon } from 'assets/imgs/airdrop/ranking/blast.svg'
import { ReactComponent as GoldIcon } from 'assets/imgs/airdrop/ranking/gold.svg'
import { ReactComponent as ToIcon } from 'assets/imgs/airdrop/ranking/to.svg'
import { useAccountDrawer } from 'components/AccountDrawer'
import { StyledDropDown, StyledSetting } from 'components/Icons/StyledIcon'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { usePtsInfoData } from 'pages/Airdrop/hooks'
import { useCallback, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { computeNumUnit } from 'utils/formatNum'

import { ReactComponent as AirdropIcon } from '../../assets/imgs/menu/airdrop.svg'
import { ReactComponent as AirdropDarkIcon } from '../../assets/imgs/menu/airdrop_dark.svg'
import { ReactComponent as LeaderBoardIcon } from '../../assets/imgs/menu/leaderboard.svg'
import { ReactComponent as LeaderBoardDarkIcon } from '../../assets/imgs/menu/leaderboard_dark.svg'
import { ReactComponent as LockIcon } from '../../assets/imgs/menu/lock.svg'
import { ReactComponent as LockDarkIcon } from '../../assets/imgs/menu/lock_dark.svg'
import { ReactComponent as OrderListIcon } from '../../assets/imgs/menu/orderList.svg'
import { ReactComponent as OrderListDarkIcon } from '../../assets/imgs/menu/orderList_dark.svg'
import { ReactComponent as PrepIcon } from '../../assets/imgs/menu/prep.svg'
import { ReactComponent as PrepDarkIcon } from '../../assets/imgs/menu/prep_dark.svg'
import { ReactComponent as RankingIcon } from '../../assets/imgs/menu/ranking.svg'
import { ReactComponent as RankingDarkIcon } from '../../assets/imgs/menu/ranking_dark.svg'
import { ReactComponent as SwapIcon } from '../../assets/imgs/menu/swap.svg'
import { ReactComponent as SwapDarkIcon } from '../../assets/imgs/menu/swap_dark.svg'
import { ReactComponent as VoteIcon } from '../../assets/imgs/menu/vote.svg'
import { ReactComponent as VoteDarkIcon } from '../../assets/imgs/menu/vote_dark.svg'
import { HoverBar } from './HoverBar'
import { NavDropdown } from './NavDropdown'
import StyledAirdropM from './StyledAirdropM'

const StyledNavBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1;
  .navItem {
    /* padding: 8px 16px; */
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    &:hover {
      background: ${({ theme }) => theme.menuHover};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xl}px) {
    gap: 12px;
    justify-content: space-between;
    .navItem {
      /* padding: 8px 10px; */
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    width: 100%;
    gap: 0;
    .navItem {
      /* padding: 8px 4px; */
      flex: 1;
      &:hover {
        background: transparent;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    .navItem {
      width: fit-content;
    }
  }
`

const StyledMenuList = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;
  padding: 4px 8px;
  gap: 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: row;
    gap: 4px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    gap: 2px;
  }
`

const StyledBlastList = styled(NavLink)`
  text-decoration: none;
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`

const StyledMenuItem = styled(StyledNavLink)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  border-radius: 4px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
    flex: 1;
  }
`

const StyledDownMenuItem = styled(StyledMenuItem)`
  min-height: 36px;
  &:hover {
    background: ${({ theme }) => theme.menuHover};
  }
`

const StyledTotalPointBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  background: ${({ theme }) => theme.blast};
  border-radius: 12px 12px 0 0;
`

const StyledLinkTo = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
`

export default function NavMenuTabs() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isSmallScreen2 = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const isSmallScreen3 = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)

  const [isTradeOpen, setIsTradeOpen] = useState(false)
  const [isLiquidityOpen, setIsLiquidityOpen] = useState(false)
  const [isGovernanceOpen, setIsGovernanceOpen] = useState(false)
  const [isAirdropOpen, setIsAirdropOpen] = useState(false)
  const tradeRef = useRef<HTMLDivElement>(null)
  const liquidityRef = useRef<HTMLDivElement>(null)
  const governanceRef = useRef<HTMLDivElement>(null)
  const airdropRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  const toggleTradeOpen = () => {
    setIsTradeOpen(!isTradeOpen)
  }

  const toggleLiquidityOpen = () => {
    setIsLiquidityOpen(!isLiquidityOpen)
  }

  const toggleGovernanceOpen = () => {
    setIsGovernanceOpen(!isGovernanceOpen)
  }

  const toggleAirdropOpen = () => {
    setIsAirdropOpen(!isAirdropOpen)
  }

  useOnClickOutside(tradeRef, isTradeOpen ? toggleTradeOpen : undefined)
  useOnClickOutside(liquidityRef, isLiquidityOpen ? toggleLiquidityOpen : undefined)
  useOnClickOutside(governanceRef, isGovernanceOpen ? toggleGovernanceOpen : undefined)
  useOnClickOutside(airdropRef, isAirdropOpen ? toggleAirdropOpen : undefined)

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {
    sendAnalyticsEvent(InterfaceEventName.ACCOUNT_DROPDOWN_BUTTON_CLICKED)
    toggleAccountDrawer()
  }, [toggleAccountDrawer])

  const theme = useTheme()

  const tradeNavList = [
    {
      title: 'Swap',
      href: '/swap',
      icon: theme.darkMode ? <SwapDarkIcon /> : <SwapIcon />,
    },
    {
      title: 'Perp',
      href: '/perp',
      icon: theme.darkMode ? <PrepDarkIcon /> : <PrepIcon />,
    },
    {
      title: 'LeaderBoard',
      href: '/leaderBoard',
      icon: theme.darkMode ? <LeaderBoardDarkIcon /> : <LeaderBoardIcon />,
    },
    {
      title: 'OrderList',
      href: '/orderList',
      icon: theme.darkMode ? <OrderListDarkIcon /> : <OrderListIcon />,
    },
  ]

  const governanceNavList = [
    {
      title: 'Lock',
      href: '/lock',
      icon: theme.darkMode ? <LockDarkIcon /> : <LockIcon />,
    },
    {
      title: 'Vote',
      href: '/vote',
      icon: theme.darkMode ? <VoteDarkIcon /> : <VoteIcon />,
    },
  ]

  const airdropNavList = [
    {
      title: 'Points',
      href: '/points',
      icon: theme.darkMode ? <AirdropDarkIcon /> : <AirdropIcon />,
    },
    {
      title: 'Ranking',
      href: '/ranking',
      icon: theme.darkMode ? <RankingDarkIcon /> : <RankingIcon />,
    },
  ]

  const { totalPts, totalGold, totalPoint } = usePtsInfoData()

  return (
    <Box display="flex" alignItems="center" width="100%" gap="4px">
      <StyledNavBox>
        <div className="navItem" ref={tradeRef}>
          <HoverBar
            textProps={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="4px"
                p={isSmallScreen2 ? '8px 4px' : isSmallScreen ? '8px 10px' : '8px 16px'}
              >
                <ThemedText.TextPrimary fontSize={isSmallScreen3 ? 12 : isSmallScreen2 ? 14 : 16}>
                  <Trans>Trade</Trans>
                </ThemedText.TextPrimary>
                <StyledDropDown />
              </Box>
            }
            navDown={
              <NavDropdown>
                <StyledMenuList onMouseLeave={toggleTradeOpen}>
                  {tradeNavList.map(({ title, href, icon }) => (
                    <StyledDownMenuItem to={href} onClick={toggleTradeOpen} key={title}>
                      <Box display="flex" alignItems="center" ml="8px">
                        {icon}
                      </Box>
                      <ThemedText.TextPrimary fontSize={isSmallScreen2 ? 14 : 16}>
                        <Trans>{title}</Trans>
                      </ThemedText.TextPrimary>
                    </StyledDownMenuItem>
                  ))}
                </StyledMenuList>
              </NavDropdown>
            }
          />
        </div>
        {isSmallScreen2 && (
          <Box p={isSmallScreen3 ? '0 2px' : '0 4px'}>
            <ThemedText.TextPrimary color="swapSectionBorder">|</ThemedText.TextPrimary>
          </Box>
        )}
        <StyledNavLink to="/pools" className="navItem">
          <Box display="flex" p={isSmallScreen2 ? '8px 4px' : isSmallScreen ? '8px 10px' : '8px 16px'}>
            <ThemedText.TextPrimary fontSize={isSmallScreen3 ? 12 : isSmallScreen2 ? 14 : 16}>
              <Trans>Pools</Trans>
            </ThemedText.TextPrimary>
          </Box>
        </StyledNavLink>
        {isSmallScreen2 && (
          <Box p={isSmallScreen3 ? '0 2px' : '0 4px'}>
            <ThemedText.TextPrimary color="swapSectionBorder">|</ThemedText.TextPrimary>
          </Box>
        )}
        <div className="navItem" ref={governanceRef}>
          <HoverBar
            textProps={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="4px"
                p={isSmallScreen2 ? '8px 4px' : isSmallScreen ? '8px 10px' : '8px 16px'}
              >
                <ThemedText.TextPrimary fontSize={isSmallScreen3 ? 12 : isSmallScreen2 ? 14 : 16}>
                  <Trans>Governance</Trans>
                </ThemedText.TextPrimary>
                <StyledDropDown />
              </Box>
            }
            navDown={
              <NavDropdown>
                <StyledMenuList onMouseLeave={toggleGovernanceOpen}>
                  {governanceNavList.map(({ title, href, icon }) => (
                    <StyledDownMenuItem to={href} onClick={toggleGovernanceOpen} key={title}>
                      <Box display="flex" alignItems="center" ml="8px">
                        {icon}
                      </Box>
                      <ThemedText.TextPrimary fontSize={isSmallScreen2 ? 14 : 16}>
                        <Trans>{title}</Trans>
                      </ThemedText.TextPrimary>
                    </StyledDownMenuItem>
                  ))}
                </StyledMenuList>
              </NavDropdown>
            }
          />
        </div>
        {/* {isSmallScreen2 && (
          <Box p={isSmallScreen3 ? '0 2px' : '0 4px'}>
            <ThemedText.TextPrimary color="swapSectionBorder">|</ThemedText.TextPrimary>
          </Box>
        )} */}
        {isSmallScreen2 && (
          <div className="navItem" ref={airdropRef}>
            <HoverBar
              textProps={
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="4px"
                  p={isSmallScreen2 ? '8px 4px' : isSmallScreen ? '8px 10px' : '8px 16px'}
                >
                  <StyledAirdropM />
                </Box>
              }
              navDown={
                <NavDropdown style={{ padding: '0 0 8px 0' }}>
                  <StyledBlastList to="/points" onMouseLeave={toggleAirdropOpen}>
                    <StyledTotalPointBox>
                      <Box display="flex" flexDirection="column" gap="4px">
                        <ThemedText.TextSecondary
                          fontSize={12}
                          fontWeight={700}
                          className=""
                          style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                        >
                          <Trans>Total Points</Trans>
                        </ThemedText.TextSecondary>
                        <Box display="flex" alignItems="center" gap="4px" className="">
                          <Box display="flex" alignItems="baseline" gap="4px">
                            <ThemedText.TextPrimary
                              fontWeight={700}
                              fontSize={14}
                              style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                            >
                              {computeNumUnit(totalPoint, 2)}
                            </ThemedText.TextPrimary>
                            <ThemedText.TextSecondary
                              fontSize={12}
                              fontWeight={400}
                              style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                            >
                              PTS.
                            </ThemedText.TextSecondary>
                          </Box>
                        </Box>
                      </Box>
                      <StyledLinkTo>
                        <ToIcon />
                      </StyledLinkTo>
                    </StyledTotalPointBox>
                    <Box display="flex" flexDirection="column" gap="8px" p="10px 16px 10px 8px">
                      <Box display="flex" alignItems="center" justifyContent="space-between" gap="8px" className="gold">
                        <Box display="flex" alignItems="center" gap="4px">
                          <GoldIcon width="12px" height="12px" />
                          <ThemedText.TextSecondary fontSize={12} fontWeight={600} className="">
                            <Trans>Blast Gold</Trans>
                          </ThemedText.TextSecondary>
                        </Box>
                        <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="">
                          {computeNumUnit(totalGold, 2)}
                        </ThemedText.TextPrimary>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="8px"
                        className="point"
                      >
                        <Box display="flex" alignItems="center" gap="4px">
                          <BlastIcon width="12px" height="12px" />
                          <ThemedText.TextSecondary fontSize={12} fontWeight={600} className="">
                            <Trans>Blast Point</Trans>
                          </ThemedText.TextSecondary>
                        </Box>
                        <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="">
                          {computeNumUnit(totalPts, 2)}
                        </ThemedText.TextPrimary>
                      </Box>
                    </Box>
                  </StyledBlastList>
                </NavDropdown>
              }
            />
          </div>
        )}
      </StyledNavBox>
      {isSmallScreen2 && (
        <Box
          display="flex"
          alignItems="center"
          p={isSmallScreen3 ? '0 2px' : '0 4px'}
          data-testid="settings"
          onClick={handleWalletDropdownClick}
        >
          <StyledSetting />
        </Box>
      )}
    </Box>
  )
}
