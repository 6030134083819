import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { ReactComponent as AirdropIcon } from 'assets/imgs/airdrop.svg'
import { ReactComponent as BlastIcon } from 'assets/imgs/airdrop/ranking/blast.svg'
import { ReactComponent as GoldIcon } from 'assets/imgs/airdrop/ranking/gold.svg'
import { usePtsInfoData } from 'pages/Airdrop/hooks'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { computeNumUnit } from 'utils/formatNum'

const StyledAirdropBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.blast};
  border-radius: 8px;
  .point,
  .gold {
    height: 36px;
    padding: 0 8px;
  }
  .line {
    background: ${({ theme }) => theme.secondaryBorder};
    height: 24px;
    width: 1px;
  }
  .airdrop {
    height: 36px;
    padding: 0 8px;
    background: ${({ theme }) => theme.blast};
    border-radius: 0 8px 8px 0;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 8px 0px #6e6e0040;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    padding: 10px 8px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 4px 2px;
  }
`

export default function StyledAirdrop() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const navigate = useNavigate()

  const { totalPts, totalGold, totalPoint } = usePtsInfoData()

  return (
    <StyledAirdropBox
      onClick={() => {
        navigate('/points')
      }}
    >
      <Box display="flex" alignItems="center" gap="8px" className="gold">
        <GoldIcon />
        <Box display="flex" flexDirection="column">
          <ThemedText.TextSecondary fontSize={12} fontWeight={600} className="">
            <Trans>Blast Gold</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="">
            {computeNumUnit(totalGold)}
          </ThemedText.TextPrimary>
        </Box>
      </Box>
      <div className="line" />
      <Box display="flex" alignItems="center" gap="8px" className="point">
        <BlastIcon />
        <Box display="flex" flexDirection="column">
          <ThemedText.TextSecondary fontSize={12} fontWeight={600} className="">
            <Trans>Blast Point</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="">
            {computeNumUnit(totalPoint, 2)}
          </ThemedText.TextPrimary>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap="4px" className="airdrop">
        <AirdropIcon />
        <Box display="flex" alignItems="baseline" gap="4px">
          <ThemedText.TextPrimary
            fontWeight={isSmallScreen ? 400 : 700}
            fontSize={isSmallScreen ? 12 : 16}
            style={{ color: 'rgba(0, 0, 0, 0.87)' }}
          >
            {computeNumUnit(totalPts, 2)}
          </ThemedText.TextPrimary>
          <ThemedText.TextSecondary fontSize={12} fontWeight={400} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            PTS.
          </ThemedText.TextSecondary>
        </Box>
      </Box>
    </StyledAirdropBox>
  )
}
